/* eslint-disable react-refresh/only-export-components */
import { lazy } from "react";
import { IRoute } from "./types";

const NotFound = lazy(() => import("pages/exceptions/NotFound"));
const Home = lazy(() => import("pages/dashboard/home"));
const Admins = lazy(() => import("pages/dashboard/admins"));
const Customers = lazy(() => import("pages/dashboard/customers"));
const CustomerInfo = lazy(() => import("pages/dashboard/customers/view"));
const Organizations = lazy(() => import("pages/dashboard/organization"));
const ContactBooks = lazy(
  () => import("pages/dashboard/organization/contact_book")
);
const OrganizationCreate = lazy(
  () => import("pages/dashboard/organization/create")
);
const OrganizationUpdate = lazy(
  () => import("pages/dashboard/organization/update")
);
const ParkingSettings = lazy(
  () => import("pages/dashboard/organization/parking_settings")
);
const GateSettings = lazy(() => import("pages/dashboard/organization/gate"));

const Buildings = lazy(() => import("pages/dashboard/building"));
const BuildingCreate = lazy(() => import("pages/dashboard/building/create"));
const BuildingUpdate = lazy(() => import("pages/dashboard/building/update"));

const Equipments = lazy(() => import("pages/dashboard/equipment"));
const EquipmentCreate = lazy(() => import("pages/dashboard/equipment/create"));
const EquipmentUpdate = lazy(() => import("pages/dashboard/equipment/update"));

const Cars = lazy(() => import("pages/dashboard/car"));
const CarLogs = lazy(() => import("pages/dashboard/car/log"));
const CarCreate = lazy(() => import("pages/dashboard/car/create"));
const CarUpdate = lazy(() => import("pages/dashboard/car/update"));

const Payments = lazy(() => import("pages/dashboard/payment"));
const QPayInvoices = lazy(() => import("pages/dashboard/qpay"));

const Banners = lazy(() => import("pages/dashboard/banner"));
const BannerLogs = lazy(() => import("pages/dashboard/banner/log"));
const BannerCreate = lazy(
  () => import("pages/dashboard/banner/tabs/banners/create")
);
const BannerUpdate = lazy(
  () => import("pages/dashboard/banner/tabs/banners/update")
);

const Ownerships = lazy(() => import("pages/dashboard/owner/ownership"));
const OwnershipInfoPage = lazy(
  () => import("pages/dashboard/owner/ownership/view")
);
const PropertiesPage = lazy(() => import("pages/dashboard/owner/property"));
const PropertyInfoPage = lazy(
  () => import("pages/dashboard/owner/property/view")
);
const PropertyLogs = lazy(() => import("pages/dashboard/owner/property/log"));

const InquiryPage = lazy(() => import("pages/dashboard/inquiry"));
const SurveyPage = lazy(() => import("pages/dashboard/survey"));
const ParkingPage = lazy(() => import("pages/dashboard/parking"));
const ParkingInvitePage = lazy(() => import("pages/dashboard/parking/invite"));

const PostPage = lazy(() => import("pages/dashboard/post"));
const CentralizedNotifPage = lazy(
  () => import("pages/dashboard/centralized_notif")
);

const OpenClosePage = lazy(() => import("pages/dashboard/report/open_close"));
const ParkingInviteReportPage = lazy(
  () => import("pages/dashboard/report/parking_invite")
);

const dashboardRoutes: IRoute[] = [
  {
    key: "home",
    path: "home",
    component: <Home />,
  },
  {
    key: "admin",
    path: "admin",
    component: <Admins />,
  },
  {
    key: "customer",
    path: "customer",
    component: <Customers />,
  },
  {
    key: "payment",
    path: "payment",
    component: <Payments />,
  },
  {
    key: "qpay",
    path: "qpay",
    component: <QPayInvoices />,
  },

  {
    key: "customer/view/:id",
    path: "customer/view/:id",
    component: <CustomerInfo />,
  },
  {
    key: "organization",
    path: "organization",
    component: <Organizations />,
  },
  {
    key: "organization/contact_book/:org_id",
    path: "organization/contact_book/:org_id",
    component: <ContactBooks />,
  },
  {
    key: "organization/parking_settings/:id",
    path: "organization/parking_settings/:id",
    component: <ParkingSettings />,
  },
  {
    key: "organization/gate/:id",
    path: "organization/gate/:id",
    component: <GateSettings />,
  },
  {
    key: "organization/create",
    path: "organization/create",
    component: <OrganizationCreate />,
  },
  {
    key: "organization/update/:id",
    path: "organization/update/:id",
    component: <OrganizationUpdate />,
  },
  {
    key: "building",
    path: "building",
    component: <Buildings />,
  },
  {
    key: "building/create",
    path: "building/create",
    component: <BuildingCreate />,
  },
  {
    key: "building/update/:id",
    path: "building/update/:id",
    component: <BuildingUpdate />,
  },
  {
    key: "equipment",
    path: "equipment",
    component: <Equipments />,
  },
  {
    key: "equipment/create",
    path: "equipment/create",
    component: <EquipmentCreate />,
  },
  {
    key: "equipment/update/:id",
    path: "equipment/update/:id",
    component: <EquipmentUpdate />,
  },
  {
    key: "car",
    path: "car",
    component: <Cars />,
  },
  {
    key: "car/log",
    path: "car/log",
    component: <CarLogs />,
  },
  {
    key: "car/create",
    path: "car/create",
    component: <CarCreate />,
  },
  {
    key: "car/update/:id",
    path: "car/update/:id",
    component: <CarUpdate />,
  },
  {
    key: "banner",
    path: "banner",
    component: <Banners />,
  },
  {
    key: "banner/log",
    path: "banner/log",
    component: <BannerLogs />,
  },
  {
    key: "banner/create",
    path: "banner/create",
    component: <BannerCreate />,
  },
  {
    key: "banner/update/:id",
    path: "banner/update/:id",
    component: <BannerUpdate />,
  },
  {
    key: "owner/ownership",
    path: "owner/ownership",
    component: <Ownerships />,
  },
  {
    key: "owner/ownership/view/:id",
    path: "owner/ownership/view/:id",
    component: <OwnershipInfoPage />,
  },
  {
    key: "owner/property/:type",
    path: "owner/property/:type",
    component: <PropertiesPage />,
  },
  {
    key: "owner/property/:type/view/:id",
    path: "owner/property/:type/view/:id",
    component: <PropertyInfoPage />,
  },
  {
    key: "owner/property/:type/log",
    path: "owner/property/:type/log",
    component: <PropertyLogs />,
  },
  {
    key: "inquiry",
    path: "inquiry",
    component: <InquiryPage />,
  },
  {
    key: "survey",
    path: "survey",
    component: <SurveyPage />,
  },
  {
    key: "parking",
    path: "parking",
    component: <ParkingPage />,
  },
  {
    key: "post",
    path: "post",
    component: <PostPage />,
  },
  {
    key: "centralized_notif",
    path: "centralized_notif",
    component: <CentralizedNotifPage />,
  },
  {
    key: "parking/invite",
    path: "parking/invite",
    component: <ParkingInvitePage />,
  },
  {
    key: "report/open_close",
    path: "report/open_close",
    component: <OpenClosePage />,
  },
  {
    key: "report/parking",
    path: "report/parking",
    component: <ParkingInviteReportPage />,
  },
  {
    key: "not_found",
    path: "*",
    component: <NotFound />,
  },
];
export default dashboardRoutes;
